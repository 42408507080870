import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import useSiteMetadata from '../hooks/useSiteMetadata';

const NotFoundPage = () => {
  const { defaultTitle } = useSiteMetadata();

  return (
    <Layout>
      <section className="section section-404">
        <Link to="/">
          <img alt={`Logo da ${defaultTitle}`} src="/img/logo_large.png" />
        </Link>
        <h1 className="title has-text-white">Esta página não existe</h1>
        <Link to="/">Ir para a página inicial</Link>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
